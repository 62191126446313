/*----------------------------------------
  Chat App
------------------------------------------*/
.chat-application .chat-content-head
{
    position: relative;
    top: 35px; 

    height: 65px;
}
.chat-application .chat-content-head .header-details .app-header-icon
{
    font-size: 2rem;

    vertical-align: bottom;
}

.chat-application .sidenav-trigger
{
    display: none;
}

.chat-application .small-icons
{
    font-size: 1.25rem;
    line-height: 1.65rem;
}

.chat-application .truncate
{
    overflow: hidden;

    width: 175px;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.chat-application .app-chat .chat-content
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
}
.chat-application .app-chat .chat-content .sidebar
{
    width: 360px;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-header
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding: 1rem;

    border-bottom: 1px solid #eee; 

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-header .option-icon i.material-icons
{
    font-size: 1.85rem;

    cursor: pointer; 

    color: #9e9e9e;
}
.chat-application .app-chat .chat-content .sidebar .info-text
{
    font-size: .85rem; 

    overflow: hidden;

    width: 175px;

    white-space: nowrap;
    text-overflow: ellipsis;

    color: #616161;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding: 1rem; 

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .search-area
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    width: -webkit-calc(100% - 4rem);
    width:    -moz-calc(100% - 4rem);
    width:         calc(100% - 4rem); 

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .search-area .search-icon
{
    font-size: 1.45rem; 

    height: 41px;
    margin-right: 0 !important;
    padding: .75rem .25rem .35rem .75rem;

    color: #9e9e9e;
    border-radius: 2rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #eceff1;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .search-area .app-filter
{
    height: 35px;
    margin-bottom: 0; 
    padding: .2rem;

    color: #9e9e9e;
    border: none;
    border: none;
    border-radius: 2rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #eceff1;

    -webkit-box-flex: 1;
    -webkit-flex: 1;
       -moz-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .search-area .app-filter:focus
{
    border-bottom: none !important;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .search-area .app-filter::-webkit-input-placeholder
{
    opacity: 1;
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9e9e9e;
    /* Firefox */
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .search-area .app-filter::-moz-placeholder
{
    opacity: 1;
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9e9e9e;
    /* Firefox */
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .search-area .app-filter::placeholder
{
    opacity: 1;
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9e9e9e;
    /* Firefox */
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .search-area .app-filter:-ms-input-placeholder
{
    /* Internet Explorer 10-11 */
    color: #9e9e9e;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .search-area .app-filter::-ms-input-placeholder
{
    /* Microsoft Edge */
    color: #9e9e9e;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-search .add-user i.add-user-icon
{
    font-size: 1.45rem; 

    padding: .6rem;

    color: #fff;
    border-radius: 50%;
    background: #212121;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-chat
{
    position: relative; 

    overflow: hidden;

    height: -webkit-calc( 100vh - 400px);
    height:    -moz-calc( 100vh - 400px);
    height:         calc( 100vh - 400px);
}
.chat-application .app-chat .chat-content .sidebar .sidebar-chat .chat-list .chat-user
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-bottom: 1px;
    padding: 1rem;

    cursor: pointer;

    border-right: 3px solid transparent;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-chat .chat-list .chat-user.active,
.chat-application .app-chat .chat-content .sidebar .sidebar-chat .chat-list .chat-user:hover
{
    border-right: 3px solid #212121; 
    background-color: #eceff1;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-chat .chat-list .chat-user .info-section
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
       -moz-box-align: end;
    -ms-flex-align: end;
            align-items: flex-end;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-chat .chat-list .chat-user .info-section .star-timing
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    width: 75px; 

    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
    -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-chat .chat-list .chat-user .info-section .star-timing .favorite
{
    line-height: 0;

    margin-right: .35rem;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-chat .chat-list .chat-user .info-section .star-timing .time
{
    font-size: .75rem;

    color: #616161;
}
.chat-application .app-chat .chat-content .sidebar .sidebar-chat .chat-list .chat-user .info-section .badge
{
    font-size: .75rem; 

    width: 20px;
    margin-top: .25rem;
}
.chat-application .app-chat .chat-content .media-image.online,
.chat-application .app-chat .chat-content .media-image.away,
.chat-application .app-chat .chat-content .media-image.offline
{
    display: block;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
       -moz-box-align: end;
    -ms-flex-align: end;
            align-items: flex-end;
}
.chat-application .app-chat .chat-content .media-image.online:after,
.chat-application .app-chat .chat-content .media-image.away:after,
.chat-application .app-chat .chat-content .media-image.offline:after
{
    width: 12px;
    height: 12px;
    margin-top: -10px;

    content: '';

    border-radius: 50%;
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .8);
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .8);
}
.chat-application .app-chat .chat-content .media-image.online.online:after,
.chat-application .app-chat .chat-content .media-image.away.online:after,
.chat-application .app-chat .chat-content .media-image.offline.online:after
{
    background-color: #4caf50;
}
.chat-application .app-chat .chat-content .media-image.online.away:after,
.chat-application .app-chat .chat-content .media-image.away.away:after,
.chat-application .app-chat .chat-content .media-image.offline.away:after
{
    background-color: #ff9800;
}
.chat-application .app-chat .chat-content .media-image.online.offline:after,
.chat-application .app-chat .chat-content .media-image.away.offline:after,
.chat-application .app-chat .chat-content .media-image.offline.offline:after
{
    background-color: #f44336;
}
.chat-application .app-chat .chat-content .chat-content-area
{
    width: 100%;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-header
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding: 1rem;

    border-bottom: 1px solid #eee; 

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-header .media-image img
{
    max-width: 49px;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-header .chat-text
{
    font-size: .85rem; 

    color: #616161;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-header .option-icon
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    width: 125px;

    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
            justify-content: space-around;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-header .option-icon i
{
    cursor: pointer; 

    color: #9e9e9e;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area
{
    position: relative; 

    overflow: hidden;

    height: -webkit-calc( 100vh - 400px);
    height:    -moz-calc( 100vh - 400px);
    height:         calc( 100vh - 400px);
    padding: 1rem;

    background: url('../../images/gallery/chat-bg.jpg') repeat scroll 0 0;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats
{
    padding: 0;
}

.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin: .5rem 1rem; 

    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
    -ms-flex-pack: start;
            justify-content: flex-start;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat.chat-right
{
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
    -ms-flex-pack: end;
            justify-content: flex-end;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat.chat-right .chat-avatar
{
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
    -ms-flex-order: 2;
            order: 2;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat.chat-right .chat-body
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
       -moz-box-align: end;
    -ms-flex-align: end;
            align-items: flex-end;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat.chat-right .chat-body .chat-content
{
    text-align: right;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat.chat-right .chat-body .chat-text
{
            flex-direction: row-reverse; 

    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
       -moz-box-orient: horizontal;
       -moz-box-direction: reverse;
        -ms-flex-direction: row-reverse;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat.chat-right .chat-body .chat-text p
{
    color: #fff; 
    background-color: #2196f3;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat.chat-right .chat-body .chat-text:first-child:before
{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;

    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 3px -5px;
       -moz-transform-origin: 3px -5px;
        -ms-transform-origin: 3px -5px;
         -o-transform-origin: 3px -5px;
            transform-origin: 3px -5px; 

    border-top-color: #2196f3 !important;
    border-right-color: #2196f3 !important;
    border-bottom-color: transparent;
    border-left-color: transparent;
    -webkit-box-shadow: 2px -2px 4px -3px rgba(0, 0, 0, .4);
            box-shadow: 2px -2px 4px -3px rgba(0, 0, 0, .4);
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat.chat-right .chat-body .chat-text:not(:first-child)
{
    margin-right: 2rem;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat .chat-avatar .avatar img
{
    width: 40px;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat .chat-body
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
    -ms-flex-align: start;
            align-items: flex-start;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat .chat-body .chat-text
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex; 

    margin: 0 1rem 1rem;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat .chat-body .chat-text p
{
    max-width: 400px; 
    padding: .5rem 1rem;

    color: #757575;
    border-radius: 5px;
    background-color: #fff;
    -webkit-box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .14);
            box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .14);
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat .chat-body .chat-text:first-child:before
{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    width: 0;
    height: 0;
    margin-top: 10px;

    content: '';
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 12px 18px;
       -moz-transform-origin: 12px 18px;
        -ms-transform-origin: 12px 18px;
         -o-transform-origin: 12px 18px;
            transform-origin: 12px 18px; 

    border: 7px solid transparent;
    border-bottom-color: #fff;
    border-left-color: #fff;
    -webkit-box-shadow: -2px 3px 4px -3px rgba(0, 0, 0, .4);
            box-shadow: -2px 3px 4px -3px rgba(0, 0, 0, .4);
}
.chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat .chat-body .chat-text:not(:first-child)
{
    margin-left: 2rem;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer
{
    margin: 1rem;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input .message
{
    margin-right: 1.5rem;
    padding: 0 1.5rem;

    color: #9e9e9e; 
    border-bottom: 0;
    border-radius: 5px;
    background-color: #eceff1;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input .message:focus
{
    border-bottom: 0; 
    -webkit-box-shadow: none;
            box-shadow: none;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input .message::-webkit-input-placeholder
{
    opacity: 1;
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9e9e9e;
    /* Firefox */
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input .message::-moz-placeholder
{
    opacity: 1;
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9e9e9e;
    /* Firefox */
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input .message::placeholder
{
    opacity: 1;
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9e9e9e;
    /* Firefox */
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input .message:-ms-input-placeholder
{
    /* Internet Explorer 10-11 */
    color: #9e9e9e;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input .message::-ms-input-placeholder
{
    /* Microsoft Edge */
    color: #9e9e9e;
}
.chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input .send
{
    height: 42px;
    padding: .3rem 3rem .3rem 2.5rem;

    border-radius: .25rem;
}

.chat-application .app-chat .sidenav-trigger
{
    position: absolute;
    top: 102px;

    display: none;
}

.page-header-dark .chat-application .chat-content-head .header-details .sidebar-title
{
    color: #fff;
}
.page-header-dark .chat-application .chat-content-head .header-details .sidebar-title .app-header-icon
{
    color: #fff;
}

.page-header-dark .chat-application .app-chat .sidenav-trigger i
{
    color: #fff;
}

@media screen and (max-width: 899px)
{
    .horizontal-layout .app-chat .sidebar .sidebar-content .sidebar-menu#sidebar-list
    {
        height: auto;
    }
}

@media (min-width: 900px)
{
    .chat-application .app-sidebar
    {
        -webkit-transform: none !important;
           -moz-transform: none !important;
            -ms-transform: none !important;
             -o-transform: none !important;
                transform: none !important;
    }
    .sidenav-overlay
    {
        display: none !important;

        opacity: 0 !important;
    }
}

@media (max-width: 900px)
{
    .sidenav-overlay
    {
        z-index: 995;
    }
    .chat-application .app-sidebar
    {
        width: 320px; 

        -webkit-transform: translateX(-105%);
           -moz-transform: translateX(-105%);
            -ms-transform: translateX(-105%);
             -o-transform: translateX(-105%);
                transform: translateX(-105%);
    }
    .chat-application .chat-content-head .header-details
    {
        margin-left: 3rem;
    }
    .chat-application .app-chat .sidenav-trigger
    {
        display: block !important;
    }
    .chat-application .app-chat .chat-content
    {
        display: block;
    }
    .chat-application .app-chat .chat-content .sidebar .sidebar-chat
    {
        height: -webkit-calc( 100vh - 225px);
        height:    -moz-calc( 100vh - 225px);
        height:         calc( 100vh - 225px);
    }
    .chat-application .app-chat .chat-content .sidebar #sidebar-list.sidebar-show
    {
        z-index: 996;
    }
}

@media (max-width: 600px)
{
    .sidebar .sidebar-content .sidenav
    {
        top: 56px !important;
    }
    .chat-application .app-chat .sidenav-trigger
    {
        top: 93px;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px)
{
    .chat-application .app-chat .chat-content .sidebar
    {
        width: 300px;
    }
}

@media screen and (max-width: 540px)
{
    .chat-application .app-chat .chat-content .chat-content-area .chat-footer .chat-input .send
    {
        padding-left: 1.8rem;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat .chat-body :first-child.chat-text::before
    {
        content: none;
    }
    .chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat .chat-body :not(:first-child).chat-text
    {
        margin-left: 1rem;
    }
    .chat-application .app-chat .chat-content .chat-content-area .chat-area .chats .chat-right.chat .chat-body :not(:first-child).chat-text
    {
        margin-right: 1rem;
    }
}
